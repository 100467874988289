<template>
  <div class="container">
    <headers></headers>
    <right></right>
    <scrollpage></scrollpage>
    <word ref="word"></word>

    <div class="w box_sizing width_box my_wrap">
      <div class="left_position">
        <left></left>
      </div>

      <div class="question_list_wrap box_sizing">
<!--        <el-button style="margin-bottom: 20px" @click="$router.go(-1)"-->
<!--          >返回</el-button-->
<!--        >-->

        <div class="question_type_box box_sizing flex_box flex_align_center flex_justify_between">
<!--          <div class="question_list_left flex_1 flex_box flex_align_center">-->
<!--            <div class="question_type_labels box_sizing">RFIB</div>-->
<!--            <div class="question_type_name">{{ detail.topic_type_name }}</div>-->
<!--            <div class="question_type_number">-->
<!--              {{ detail.count }}/{{ detail.current }}-->
<!--            </div>-->
<!--          </div>-->
<!--          <img-->
<!--            src="../../assets/images/guanbi.png"-->
<!--            class="question_type_right"-->
<!--            alt="返回上一页"-->
<!--            @click="goBack"-->
<!--          />-->
          <navtitle :topic_id="id" title="阅读填空" pinyin="FIB_R" page="questionType11"></navtitle>

        </div>

        <div class="topic_wrap">
          <div class="topic_type_name">{{ detail.name }}</div>
          <div class="topic_type_info box_sizing flex_box flex_align_center">
            <div class="topic_type_info_time">{{ detail.createtime_text }}</div>
            <div class="question_lian_shoucang">
              <img
                class="question_list_jiangjieimg"
                v-if="!detail.collect || detail.collect == 1"
                src="../../assets/images/shoucang1.png"
                alt=""
              />
              <img
                class="question_list_jiangjieimg"
                v-if="detail.collect == 2"
                src="../../assets/images/shoucang2.png"
                alt=""
              />
              <img
                class="question_list_jiangjieimg"
                v-if="detail.collect == 3"
                src="../../assets/images/shoucang3.png"
                alt=""
              />
              <img
                class="question_list_jiangjieimg"
                v-if="detail.collect == 4"
                src="../../assets/images/shoucang4.png"
                alt=""
              />
              <img
                class="question_list_jiangjieimg"
                v-if="detail.collect == 5"
                src="../../assets/images/shoucang5.png"
                alt=""
              />
            </div>
            <!-- <img
              src="../../assets/images/type_fenxiang.png"
              class="topic_type_info_fenxiang"
              @click="copyDomain()"
              alt=""
            /> -->
          </div>
          <div class="topic_type_title">{{ detail.describe }}</div>
          <div
            class="dati_time box_sizing flex_box flex_align_center justify_end"
          >
            答题时间：{{ datiTime }}
          </div>
          <div class="topic_type_timu_box box_sizing" v-if="detail.extend.FIB">
            <span v-for="(item, index) in detail.extend.FIB" :key="index">
              <span v-if="item.index == 0" @click="dianjiClick(item.word)" v-html="item.word">
              </span>
              <span
                class="panding_change"
                :data-txt="item.word"
                v-else
                @dragenter="deagenterDiv"
                @dragover="dragoverDiv"
                @drop="dropDiv"
                style="
                  height: 16px;
                  border-bottom: 1px solid rgba(6, 18, 30, 1);
                  width: 150px;
                  text-align: center;
                  margin: 3px;
                "
                v-html="daanObj[item.word]"
              ></span>
              <span
                class="panding_change"
                v-if="spanShow && item.index == 1"
                :style="{
                  color: item.word === daanObj[item.word] ? '#0ff507' : 'red',
                }"
                >&nbsp;({{ item.word }})&nbsp;</span
              >
            </span>
          </div>

          <div
            class="box_sizing flex_box flex-warp"
            style="width: 100%"
            v-if="detail.extend.keyword"
          >
            <div v-for="(item, index) in detail.extend.keyword" :key="index">
              <div
                class="yuansu_list box_sizing flex_box flex_align_center flex_justify_center"
                @drag="dragDiv"
                @dragover="dropOver($event)"
                :draggable="true"
                :data-text="item"
                @dragstart="(e) => dragstartHandler(e,index)"
                v-if="daanIndexArr.indexOf(index) < 0"
                v-html="item"
              >
              </div>
            </div>
          </div>

          <!-- <div
            class="dati_time box_sizing flex_box flex_align_center justify_end"
            v-if="daojishiTime > 0"
          >
            {{ daojishiTime }}s后开始答题
          </div>-->

          <!-- <div class="dati_time box_sizing flex_box flex_align_center justify_end">答题时间：{{datiTime}}</div> -->

          <!-- <div class=" box_sizing">
                        <div class="flex_box flex_justify_between flex_align_center tip18">
                            <div class="flex_align_center flex_box flex_justify_between">
                                <div class="flex_box flex_align_center four">
                                    <div style="background-color: #45C6AA;"></div>答案正确
                                </div>
                                <div class="flex_box flex_align_center four">
                                    <div style="background-color: #FF1027;"></div>答案错误
                                </div>
                            </div>
                        </div>
                    </div> -->

          <!-- <div class="topic_type_answer_box box_sizing" style="height: auto">

                        <div class="topic_textarea_box">
                            <textarea placeholder="请作答" v-model="content"></textarea>
                        </div>
                        <div class="topic_textarea_btn_flex box_sizing flex_box flex_align_center flex_justify_between">
                            <div class="topic_textarea_left"></div>
                            <div class="topic_textarea_right box_sizing flex_box flex_align_center">
                                <div class="topic_textarea_right_btn">CUT</div>
                                <div class="topic_textarea_right_btn">COPY</div>
                                <div class="topic_textarea_right_btn">PASTE</div>
                            </div>
                        </div>
                    </div>  -->

          <!-- 所得分数 -->
          <!-- <div class="my_fen flex_box box_sizing">
                        <span>所得分数：1/3</span>
                        <span class="my_fen_l box_sizing">（满分3分）</span>
                    </div> -->

          <div
            class="topic_type_answer_bottom box_sizing flex_box flex_align_center flex_justify_between"
          >
            <div
              class="topic_type_answer_botleft box_sizing flex_box flex_align_center"
            >
              <div class="topic_type_answer_botbtn" @click="submitAns">
                完成
              </div>
              <div class="topic_type_answer_botbtn now" @click="chongzuo">
                重做
              </div>
              <div class="">
                <span style="margin-right: 3px">答案：</span>
                <el-tooltip content="解析答案">
                  <el-switch v-model="showSwitch" active-color="#f6704b"	@change="answerChange"></el-switch>
                </el-tooltip>
              </div>
              <div class="" style="margin-left: 20px;">
                <span style="margin-right: 3px">翻译：</span>
                <el-tooltip content="解析答案">
                  <el-switch v-model="showSwitchFanyi" active-color="#f6704b"	@change="fanyiChange"></el-switch>
                </el-tooltip>
              </div>
              <!-- <div class="topic_type_answer_swich box_sizing flex_box flex_align_center">
                                <img class="topic_type_answer_swich_img" src="../../assets/images/shunxuzheng.png" alt="">
                                <div class="topic_type_answer_swich_txt">顺序</div>
                            </div> -->
            </div>
            <div
              class="topic_type_answer_botcenter box_sizing flex_box flex_align_center"
            >
              <div
                class="topic_type_answer_botcenter_list box_sizing flex_box flex_align_center"
                @click="seeDaFen"
              >
                <img
                  src="../../assets/images/dafen_icon.png"
                  class="topic_type_answer_botcenter_img"
                  alt=""
                />
                <div class="topic_type_answer_botcenter_txt">查看打分</div>
              </div>
              <div
                class="topic_type_answer_botcenter_list box_sizing flex_box flex_align_center"
                @click="shenQingPiGai"
              >
                <img
                  src="../../assets/images/shenqing_icon.png"
                  class="topic_type_answer_botcenter_img"
                  alt=""
                />
                <div class="topic_type_answer_botcenter_txt">申请批改</div>
              </div>
            </div>
            <div
              class="topic_type_answer_botright box_sizing flex_box flex_align_center"
            >
              <div class="topic_type_answer_botbtn tihao" @click="shangYiTi">上一题</div>
              <div class="topic_type_answer_botbtn" @click="xiaYiTi">
                下一题
              </div>
            </div>
          </div>
          <div v-show="showSwitch" class="common_content">
            <div v-html="detail.show_tape_text"></div>
          </div>
          <div v-show="showSwitchFanyi" class="common_content">
            <div v-html="detail.extend.fanyi"></div>
          </div>
        </div>
      </div>
    </div>

    <footers></footers>

    <!-- 弹框 -->
    <div class="model" v-if="status">
      <div class="modelContent box_sizing">
        <div class="title">{{ detail.topic_type_name }}</div>
        <!-- <div class="teacher flex_box flex_align_center box_sizing flex_justify_between">
                    <div class="flex_box flex_align_center" style="width: 40%;">
                        <img src="../../assets/images/play.png" alt="" >
                        <div class="play box_sizing">
                            <div class="topic_type1_luyin progress">
                                <div class="topic_type1_luyin1" style="width: 50%"></div>
                            </div>
                            <div class="time">00:01/01:23</div>
                        </div>
                    </div>
                    <div class="flex_align_center flex_box yin box_sizing" >
                        <img src="../../assets/images/laba.png" alt="" >
                        <div class="topic_type1_luyin progress">
                            <div class="topic_type1_luyin1" style="width: 50%"></div>
                        </div>
                    </div>

                </div> -->
        <div class="flex-warp flex_box onebox">
          <div
            class="one flex_box flex_align_center box_sizing flex-wrap"
            v-if="obj.content_score"
          >
            <div class="neirong flex_box">内容</div>
            <div class="fenshu">
              <div>得分：{{ obj.content_score.score }}分</div>
              <div style="color: #8c9198">
                满分：{{ obj.content_score.full }}分
              </div>
            </div>
          </div>
          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.format_score"
          >
            <div class="neirong flex_box">格式</div>
            <div class="fenshu">
              <div>得分：{{ obj.format_score.score }}分</div>
              <div style="color: #8c9198">
                满分：{{ obj.format_score.full }}分
              </div>
            </div>
          </div>
          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.structure"
          >
            <div class="neirong flex_box">发展结构</div>
            <div class="fenshu">
              <div>得分：{{ obj.structure.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.structure.full }}分</div>
            </div>
          </div>
          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.grammar"
          >
            <div class="neirong flex_box">语法</div>
            <div class="fenshu">
              <div>得分：{{ obj.grammar.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.grammar.full }}分</div>
            </div>
          </div>
          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.structure"
          >
            <div class="neirong flex_box">语言范围</div>
            <div class="fenshu">
              <div>得分：{{ obj.structure.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.structure.full }}分</div>
            </div>
          </div>
          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.wordScore"
          >
            <div class="neirong flex_box">词汇范围</div>
            <div class="fenshu">
              <div>得分：{{ obj.wordScore.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.wordScore.full }}分</div>
            </div>
          </div>
          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.spelling"
          >
            <div class="neirong flex_box">拼写</div>
            <div class="fenshu">
              <div>得分：{{ obj.spelling.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.spelling.full }}分</div>
            </div>
          </div>
          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.score || obj.score == 0"
          >
            <div class="fenshu">
              <div>得分：{{ obj.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.full }}分</div>
            </div>
          </div>
        </div>
        <div class="zuoda box_sizing">
          <div class="zuodatitle box_sizing">作答内容</div>
          <div class="zuodacont">
            <!--            <div class="answer_content" v-for="(item,index) in obj.content" :key="index" v-html="index+' - '+item">-->

            <!--            </div>-->
            <div>你的答案：{{ Object.values(obj.content).join(", ") }}</div>
            <div>正确答案：{{ Object.keys(obj.content).join(", ") }}</div>
          </div>
        </div>

        <div class="cancel" @click="status = false">
          <img src="../../assets/images/login_close.png" alt="" />
        </div>

        <!-- <div class="ping">
                    <img src="../../assets/images/wenhao.png" alt="">
                    <div>评分细则</div>
                </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import headers from "@/components/headers";
import footers from "@/components/footders";
import left from "@/components/left";
import right from "@/components/right";
import scrollpage from "@/components/scrollpage";
// import draggable from "vuedraggable";
import "@/css/questionList.css";
import "@/css/questiontype.css";
import "@/css/pagePing.css";
import Navtitle from "@/components/navtitle.vue";
import Word from "@/components/word.vue";

var _this, intervalTime;
export default {
  components: {
    Navtitle,
    headers,
    footers,
    left,
    right,
    scrollpage,
    Word
    // draggable
  },
  data() {
    return {
      topicExerciseId: null,
      showSwitch: false,
      showSwitchFanyi: false,
      id: "",
      detail: {},
      content: "",
      time: 0,
      list: [],
      status: false,
      obj: {},
      huida: false,
      datiTime: "00:00:00",
      daanObj: {},
      daanIndexObj: {},
      daanIndexArr: [],
      bofangStatus: false,
      daojishiTime: 40,
      audio: null,
      // 点击完成后答案显示变量
      spanShow: false,
      spanShowFanyi: false,
      drop_index: null,
      drop_before_index: null
    };
  },
  created() {
    _this = this;
    _this.getParams();
    _this.daojishi();
    // _this.daojishiBack();
  },
  methods: {
    copyDomain() {
      if (_this.topicExerciseId == null) {
        this.$message.error("请申请批改后复制链接");
        return;
      }
      _this.$copyText(_this.$global.baseUrl + "&id=" + _this.topicExerciseId);
      this.$message.success("地址复制成功");
    },
    // 进入页面倒计时40秒开始录音
    daojishiBack() {
      let daojishiBo = setInterval(() => {
        if (_this.daojishiTime <= 0) {
          clearInterval(daojishiBo);
          _this.audio.src = require("../../assets/di.mp3");
          let playPromise;
          playPromise = _this.audio.play();
          _this.playStatus = true;
          if (playPromise) {
            playPromise
              .then(() => {
                // 音频加载成功
                // 音频的播放需要耗时
              })
              .catch((e) => {
                // 音频加载失败
                console.error(e);
              });
          }
          _this.getTime();
        } else {
          _this.daojishiTime--;
        }
      }, 1000);
    },
    // 点击事件
    dianjiClick(item) {
      console.log(item);
      if(!item || item == '&nbsp;') {return;}
      this.$refs.word.word_text = item;
      this.$refs.word.getWord();
    },

    // 返回上一页
    goBack() {
      _this.$router.back();
    },

    // 在目标元素中移动
    dragoverDiv(e) {
      console.log("555555555555555555555555555555555555555");
      console.log(e);
      e.dataTransfer.dropEffect = "copy";
      e.preventDefault();
    },
    answerChange(e) {
      console.log('showSwitch',e,_this.showSwitch);
      if(e) {
        _this.spanShow = true;
      } else {
        _this.spanShow = false;
      }
    },
    fanyiChange(e) {
      console.log('showSwitchFanyi',e,_this.showSwitchFanyi);
      if(e) {
        _this.spanShowFanyi = true;
      } else {
        _this.spanShowFanyi = false;
      }
    },

    // 正在拖动
    dragDiv(e) {
      console.log("正在拖动", e);
    },

    // 阻止默认事件
    dropOver(e) {
      e.preventDefault(); // 在拖动中阻止默认事件
    },

    // 设置拖拽元素的方式
    deagenterDiv(e) {
      // console.log('444444444444444444')
      // console.log(e)
      e.dataTransfer.dropEffect = "copy";
      e.preventDefault();
    },

    // 开始拖拽
    dragstartHandler(e,index) {
      // console.log('1212121212222222222222222222拖拽')
      console.log(e.target.dataset.text);
      e.dataTransfer.effectAllowed = "copy";
      e.dataTransfer.setData("copyKey", e.target.dataset.text);
      _this.drop_index = index;
      console.log('this.drop_index',index);
      // 获取拖拽的元素
    },
    // 上一题
    shangYiTi() {
      var params = {
        topic_id: _this.id,
        type: _this.detail.topic_type_id,
        exercise_status: localStorage.getItem('lianxiId'),
        topic_collect: localStorage.getItem('collectId'), //收藏 之前添加传的
        pinlvId: localStorage.getItem('pinlvId'),
        tags: localStorage.getItem('tag_id'),
        topic_lecture_id:
          localStorage.getItem('jiangjieId') == 0 ? undefined : localStorage.getItem('jiangjieId') == 1 ? 1 : 0, //名师讲解id 0=没有讲解
      };
      _this.$axios
        .post("exercise.topic/lastTopic", params)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            _this.id = res.data.topic_id;
            console.log("当前的返回", res.data.topic_id);
            _this.$router.replace({
              path: "questionType11?id=" + res.data.topic_id + "&name=阅读填空",
            });
            location.reload();
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          _this.$message.error(err.msg);
        });
    },
    // 下一题
    xiaYiTi() {
      var params = {
        topic_id: _this.id,
        type: _this.detail.topic_type_id,
        exercise_status: localStorage.getItem('lianxiId'),
        topic_collect: localStorage.getItem('collectId'), //收藏 之前添加传的
        pinlvId: localStorage.getItem('pinlvId'),
        tags: localStorage.getItem('tag_id'),
        topic_lecture_id:
          localStorage.getItem('jiangjieId') == 0 ? undefined : localStorage.getItem('jiangjieId') == 1 ? 1 : 0, //名师讲解id 0=没有讲解
      };
      _this.$axios
        .post("exercise.topic/nextTopic", params)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            _this.id = res.data.topic_id;
            console.log("当前的返回", res.data.topic_id);
            _this.$router.replace({
              path: "questionType11?id=" + res.data.topic_id + "&name=阅读填空",
            });
            location.reload();
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          _this.$message.error(err.msg);
        });
    },
    // 拖拽到此
    dropDiv(e) {
      if (_this.spanShow) {
        _this.$message.error("答题已完成不可再操作");
        return false;
      }
      // console.log('1111111111111111111111111111111111111释放')
      // console.log(e);

      // 将拖动元素旋转到目标区域中
      var copyKey = e.dataTransfer.getData("copyKey");
      console.log(copyKey);
      _this.daanObj[e.target.dataset.txt] = copyKey;
      console.log('xxxxxx',_this.daanObj,_this.daanIndexObj);
      if(_this.daanIndexObj[e.target.dataset.txt] >= 0) { // 判断是否已选择
        _this.daanIndexArr.splice(_this.daanIndexArr.indexOf(_this.daanIndexObj[e.target.dataset.txt]),1); // 删除对应的索引值
      }
      _this.daanIndexObj[e.target.dataset.txt] = _this.drop_index;
      _this.daanIndexArr.push(_this.drop_index);
      console.log('indexof',_this.daanIndexArr);
      // detail.extend.keyword
      // _this.$for
    },

    // 释放
    shifangSpan(e) {
      console.log(e);
    },

    // 申请批改
    shenQingPiGai() {
      if (_this.huida) {
        var params = {
          topic_exercise_id: _this.topicExerciseId,
        };
        _this.$axios
          .post("exercise.topic_exercise/submit", params)
          .then((res) => {
            console.log(res);
            if (res.code == 1) {
              _this.$message.success("您已提交申请");
            } else {
              _this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
            _this.$message.error(err.msg);
          });
      } else {
        _this.$message.error("您还没答题，请先进行答题");
      }
    },

    // 查看打分
    seeDaFen() {
      if (_this.huida) {
        _this.status = true;
      } else {
        _this.$message.error("您还没答题，请先进行答题");
      }
    },

    // 重做
    chongzuo() {
      _this.time = 0;
      _this.content = "";
      let obj = {};
      _this.detail.extend.FIB.forEach((item, index) => {
        if (item.index == 1) {
          obj[item.word] = "";
        }
      });
      _this.daanIndexObj = {};
      _this.daanIndexArr = [];
      _this.daanObj = obj;
      clearInterval(intervalTime);
      _this.daojishi();
    },

    // 开始计时
    daojishi() {
      intervalTime = setInterval(() => {
        _this.time = _this.time + 1;
        _this.datiTime = _this.formateSeconds(_this.time);
      }, 1000);
    },

    //将秒转化为时分秒
    formateSeconds(endTime) {
      let secondTime = parseInt(endTime); //将传入的秒的值转化为Number
      let min = 0; // 初始化分
      let h = 0; // 初始化小时
      let result = "";
      if (secondTime > 60) {
        //如果秒数大于60，将秒数转换成整数
        min = parseInt(secondTime / 60); //获取分钟，除以60取整数，得到整数分钟
        secondTime = parseInt(secondTime % 60); //获取秒数，秒数取佘，得到整数秒数
        if (min > 60) {
          //如果分钟大于60，将分钟转换成小时
          h = parseInt(min / 60); //获取小时，获取分钟除以60，得到整数小时
          min = parseInt(min % 60); //获取小时后取佘的分，获取分钟除以60取佘的分
        }
      }
      result = `${h.toString().padStart(2, "0")}:${min
        .toString()
        .padStart(2, "0")}:${secondTime.toString().padStart(2, "0")}`;
      return result;
    },

    // 点击完成
    submitAns() {
      if (_this.showSwitch) {
        _this.$message.error("请关闭解析");
        return;
      }
      var params = {
        topic_id: _this.id,
        content: _this.daanObj,
        time: _this.time,
      };
      if (localStorage.getItem("token")) {
        _this.$axios
          .post("exercise.topic/submit", params)
          .then((res) => {
            console.log(res);
            if (res.code == 1) {
              _this.topicExerciseId = res.data.topicExerciseId;
              _this.obj = res.data;
              _this.huida = true;
              _this.spanShow = true;
              _this.$message.success("答题已完成");
              _this.status = true;
              clearInterval(intervalTime);
            } else {
              _this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
            _this.$message.error(err.msg);
          });
      } else {
        _this.$message.error("您还没登录，请先登录");
      }
    },
    // 微信分享复制链接
    copyToH5(){

    // console.log('当前的domain', item);return
      let pinyin = 'FIB_R';
      let tips = pinyin+" "+_this.detail.number+" "+" PTE易王炸AI评分 ";
      this.$copyText(tips+" "+window.location.origin+"/H5Detail?exericise_id="+_this.topicExerciseId+"&topic_id="+ _this.detail.id+"&pinyin="+pinyin+"&title="+encodeURIComponent(_this.detail.name)+"&page=questionType6");
      this.$message.success("地址复制成功")
    },

    // 获取详情
    getDetail() {
      var params = {
        topic_id: _this.id,
      };
      _this.$axios
        .post("exercise.topic/getTopicInfo", params)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            _this.detail = res.data;
            let obj = {};
            res.data.extend.FIB.forEach((item, index) => {
              //   console.log(typeof item.word);
              if (item.index == 1) {
                obj[item.word] = "";
              }
            });

            _this.daanObj = obj;
            _this.bofangStatus = false;
            _this.mouseStatus = false;
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 获取传递过来的参数
    getParams() {
      _this.id = _this.$route.query.id;
      _this.getDetail();
    },
  },
};
</script>


<style>
.list-complete-item {
  cursor: pointer;
  position: relative;
  font-size: 14px;
  padding: 5px 12px;
  display: inline-block;
  margin-right: 20px;
  width: 50px;
  height: 50px;
  border: 1px solid #bfcbd9;
  transition: all 1s;
}

.list-complete-item.sortable-chosen {
  background: #4ab7bd;
}

.list-complete-item.sortable-ghost {
  background: #30b08f;
}

.undraggable {
  background-color: red;
}

.list-complete-enter,
.list-complete-leave-active {
  opacity: 0;
}

.tip18 {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
}

.four {
  margin-right: 36px;
}

.four div {
  width: 24px;
  height: 24px;
  opacity: 1;
  margin-right: 12px;
}

.grade18 {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 22px;
  color: #f6704b;
  padding-top: 53px;
}

.yuansu_list {
  height: 36px;
  padding: 4px 24px;
  border-radius: 18px;
  margin: 12px 23px;
  background: rgba(238, 238, 238, 1);
  font-size: 14px;
  color: rgba(6, 18, 30, 1);
}

.zuodacont {
  margin-bottom: 24px;
}

.answer_content {
  float: left;
  margin-right: 30px;
}
</style>
