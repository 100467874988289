<template>
  <div class="container">
    <headers></headers>
    <right></right>
    <scrollpage></scrollpage>

    <div class="w box_sizing width_box my_wrap">
      <div class="left_position">
        <left></left>
      </div>

      <div class="question_list_wrap box_sizing">
<!--        <el-button style="margin-bottom: 20px" @click="$router.go(-1)"-->
<!--          >返回</el-button-->
<!--        >-->

        <div
          class="question_type_box box_sizing flex_box flex_align_center flex_justify_between"
        >
<!--          <div class="question_list_left flex_1 flex_box flex_align_center">-->
<!--            <div class="question_type_labels box_sizing">HIW</div>-->
<!--            <div class="question_type_name">{{ detail.topic_type_name }}</div>-->
<!--            &lt;!&ndash; <div class="question_type_number">40/200</div> &ndash;&gt;-->
<!--          </div>-->
<!--          <img-->
<!--            src="../../assets/images/guanbi.png"-->
<!--            class="question_type_right"-->
<!--            alt="返回上一页"-->
<!--            @click="goBack"-->
<!--          />-->
          <navtitle :topic_id="id" title="识别错词" pinyin="HIW" page="questionType16"></navtitle>

        </div>

        <div class="topic_wrap">
          <div class="topic_type_name">{{ detail.name }}</div>
          <div class="topic_type_info box_sizing flex_box flex_align_center">
            <div class="topic_type_info_time">{{ detail.createtime_text }}</div>
            <div class="question_lian_shoucang">
              <img
                class="question_list_jiangjieimg"
                v-if="!detail.collect || detail.collect == 1"
                src="../../assets/images/shoucang1.png"
                alt=""
              />
              <img
                class="question_list_jiangjieimg"
                v-if="detail.collect == 2"
                src="../../assets/images/shoucang2.png"
                alt=""
              />
              <img
                class="question_list_jiangjieimg"
                v-if="detail.collect == 3"
                src="../../assets/images/shoucang3.png"
                alt=""
              />
              <img
                class="question_list_jiangjieimg"
                v-if="detail.collect == 4"
                src="../../assets/images/shoucang4.png"
                alt=""
              />
              <img
                class="question_list_jiangjieimg"
                v-if="detail.collect == 5"
                src="../../assets/images/shoucang5.png"
                alt=""
              />
            </div>
            <!-- <img
              src="../../assets/images/type_fenxiang.png"
              class="topic_type_info_fenxiang"
              @click="copyDomain()"
              alt=""
            /> -->
          </div>
          <div class="topic_type_title">{{ detail.describe }}</div>
          <!-- 题目区域 -->
          <div class="mp3_tool box_sizing">
            <div style="margin: 20px 0px">
              <mp3-tool ref="mp3" :src-url="audio.src"></mp3-tool>
            </div>
          </div>
          <!-- <div class="dati_time box_sizing flex_box flex_align_center justify_end" v-if="daojishiTime > 0">{{daojishiTime}}s后开始答题</div> -->
          <div class="dati_time box_sizing flex_box flex_align_center justify_end">答题时间：{{datiTimes}}</div>
          <div class="topic_type_answer_box box_sizing" style="min-height: 380px" v-if="detail.extend && detail.extend.HIW">
            <span
              class="cuoci_ans hand_change"
              v-for="(item, index) in detail.extend.HIW"
              :class="[item.bg == 1 ? 'bg_ans' : '']"
              :key="index"
              @click="selectSpan(index)"
              v-html="item.word"
              ><span v-if="item.valid_word != '' && showSwitch" style="color: rgba(242, 127, 109, 1)">(正确答案：{{item.valid_word}})</span></span
            >
            <!-- <div class="topic_type_timu_now_box box_sizing">
                <block v-for="(item, index) in detail.extend.FIB" :key="index">
                    <span v-if='item.index == 0' >&nbsp;&nbsp; {{item.word}} &nbsp;&nbsp;</span>
                    <input type="text" class="kong" v-if="item.index == 1" value="" :data-daan='item.word' @change="inputChange" />
                </block>


            </div> -->
            <!-- <div class="my_questins4 flex_box">
              <div class="my_questins4_title flex_box" v-if="detail.extend">
                {{ detail.extend.topic }}
              </div>
              <div class="my_questins4_box flex_box" v-if="detail.extend">
                <div
                  class="my_questins4_list flex_box"
                  v-for="(item, index) in detail.extend.extend_SA"
                  :key="index"
                  @click="selectDaan(index)"
                >
                  <img
                    v-if="item.select == 0"
                    src="../../assets/images/weixuanzhong_icon@2x.png"
                  />
                  <img
                    v-else-if="item.select == 1"
                    src="../../assets/images/xuanzhong_Icon@2x.png"
                  />
                  <img
                    v-else-if="item.select == 2"
                    src="../../assets/images/zhengque_Icon@2x.png"
                  />
                  <img
                    v-else-if="item.select == 3"
                    src="../../assets/images/guanbi_icon@2x.png"
                  />
                  <div :class="[item.select == 2 ? 'my_questins4_color' : '']">
                    {{ item.key }}. {{ item.content }}
                  </div>
                </div>
              </div>
            </div> -->
          </div>

          <!-- <div
            class="dati_time box_sizing flex_box flex_align_center justify_end"
          >
            答题时间：{{ datiTime }}
          </div> -->

          <div
            class="topic_type_answer_bottom box_sizing flex_box flex_align_center flex_justify_between"
          >
            <div
              class="topic_type_answer_botleft box_sizing flex_box flex_align_center"
            >
              <div class="topic_type_answer_botbtn" @click="submitTopic">
                完成
              </div>
              <div class="topic_type_answer_botbtn now" @click="chongzuo">
                重做
              </div>
              <div class="">
                <span style="margin-right: 3px">答案：</span>
                <el-tooltip content="解析答案">
                  <el-switch v-model="showSwitch" active-color="#f6704b"	></el-switch>
                </el-tooltip>
              </div>
              <!-- <div
                class="topic_type_answer_swich box_sizing flex_box flex_align_center"
              >
                <img
                  class="topic_type_answer_swich_img"
                  @click="shunxu = 2"
                  src="../../assets/images/shunxuzheng.png"
                  v-if="shunxu == 1"
                  alt=""
                />
                <img
                  v-if="shunxu == 2"
                  @click="shunxu = 1"
                  class="topic_type_answer_swich_img"
                  src="../../assets/images/shunxufan.png"
                  alt=""
                />
                <div class="topic_type_answer_swich_txt">顺序</div>
              </div> -->
            </div>
            <div
              class="topic_type_answer_botcenter box_sizing flex_box flex_align_center"
            >
              <div
                class="topic_type_answer_botcenter box_sizing flex_box flex_align_center"
              >
                <div
                  class="topic_type_answer_botcenter_list box_sizing flex_box flex_align_center"
                  @click="seeDaFen"
                >
                  <img
                    src="../../assets/images/dafen_icon.png"
                    class="topic_type_answer_botcenter_img"
                    alt=""
                  />
                  <div class="topic_type_answer_botcenter_txt">查看打分</div>
                </div>
                <div
                  class="topic_type_answer_botcenter_list box_sizing flex_box flex_align_center"
                  @click="shenQingPiGai"
                >
                  <img
                    src="../../assets/images/shenqing_icon.png"
                    class="topic_type_answer_botcenter_img"
                    alt=""
                  />
                  <div class="topic_type_answer_botcenter_txt">申请批改</div>
                </div>
              </div>
            </div>
            <div
              class="topic_type_answer_botright box_sizing flex_box flex_align_center"
            >
              <div class="topic_type_answer_botbtn tihao" @click="shangYiTi">上一题</div>
              <div class="topic_type_answer_botbtn" @click="xiaYiTi">
                下一题
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footers></footers>

    <!-- 弹框 -->
    <div class="model" v-if="status">
      <div class="modelContent box_sizing">
        <div class="title">识别错词</div>
        <!-- <div class="teacher flex_box flex_align_center box_sizing flex_justify_between">
                    <div class="flex_box flex_align_center" style="width: 40%;">
                        <img src="../../assets/images/play.png" alt="" >
                        <div class="play box_sizing">
                            <div class="topic_type1_luyin progress">
                                <div class="topic_type1_luyin1" style="width: 50%"></div>
                            </div>
                            <div class="time">00:01/01:23</div>
                        </div>
                    </div>
                    <div class="flex_align_center flex_box yin box_sizing" >
                        <img src="../../assets/images/laba.png" alt="" >
                        <div class="topic_type1_luyin progress">
                            <div class="topic_type1_luyin1" style="width: 50%"></div>
                        </div>
                    </div>

                </div> -->
        <div class="flex-warp flex_box onebox">
          <div
            class="one flex_box flex_align_center box_sizing flex-wrap"
            v-if="obj.content_score"
          >
            <div class="neirong flex_box">内容</div>
            <div class="fenshu">
              <div>得分：{{ obj.content_score.score }}分</div>
              <div style="color: #8c9198">
                满分：{{ obj.content_score.full }}分
              </div>
            </div>
          </div>
          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.format_score"
          >
            <div class="neirong flex_box">格式</div>
            <div class="fenshu">
              <div>得分：{{ obj.format_score.score }}分</div>
              <div style="color: #8c9198">
                满分：{{ obj.format_score.full }}分
              </div>
            </div>
          </div>
          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.structure"
          >
            <div class="neirong flex_box">发展结构</div>
            <div class="fenshu">
              <div>得分：{{ obj.structure.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.structure.full }}分</div>
            </div>
          </div>
          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.grammar"
          >
            <div class="neirong flex_box">语法</div>
            <div class="fenshu">
              <div>得分：{{ obj.grammar.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.grammar.full }}分</div>
            </div>
          </div>
          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.structure"
          >
            <div class="neirong flex_box">语言范围</div>
            <div class="fenshu">
              <div>得分：{{ obj.structure.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.structure.full }}分</div>
            </div>
          </div>
          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.wordScore"
          >
            <div class="neirong flex_box">词汇范围</div>
            <div class="fenshu">
              <div>得分：{{ obj.wordScore.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.wordScore.full }}分</div>
            </div>
          </div>
          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.spelling"
          >
            <div class="neirong flex_box">拼写</div>
            <div class="fenshu">
              <div>得分：{{ obj.spelling.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.spelling.full }}分</div>
            </div>
          </div>
          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.score || obj.score >= 0"
          >
            <div class="fenshu">
              <div>得分：{{ obj.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.full }}分</div>
            </div>
          </div>
        </div>
        <div class="zuoda box_sizing">
          <div class="zuodatitle box_sizing">作答内容</div>
          <div class="zuodacont">{{ obj.content }}</div>
        </div>
        <div class="zuoda box_sizing">
          <div class="zuodatitle box_sizing">正确答案</div>
          <div class="zuodacont" style="margin-bottom: 50px; display: flex">
            <div
              v-for="(item, index) in obj.return"
              :key="index"
              style="margin-right: 10px"
            >
              {{ index }}
            </div>
          </div>
        </div>

        <div class="cancel" @click="status = false">
          <img src="../../assets/images/login_close.png" alt="" />
        </div>

        <!-- <div class="ping">
                    <img src="../../assets/images/wenhao.png" alt="">
                    <div>评分细则</div>
                </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import headers from "@/components/headers";
import footers from "@/components/footders";
import left from "@/components/left";
import right from "@/components/right";
import scrollpage from "@/components/scrollpage";
import "@/css/questionList.css";
import "@/css/questiontype.css";
import "@/css/pagePing.css";
import Navtitle from "@/components/navtitle.vue";
import Mp3Tool from "@/components/mp3Tool.vue";

var _this, intervalTime, intervalTimes;
export default {
  components: {
    Navtitle,
    headers,
    footers,
    left,
    right,
    scrollpage,
    Mp3Tool,
  },
  data() {
    return {
      showSwitch:false,
      topicExerciseId: null,
      id: "",
      detail: {},
      name: "",
      ansId: "",
      time: 0,
      list: [],
      status: false,
      obj: {},
      huida: false,
      shunxu: 2,
      len: 0,
      bofangTxt: "播放录音",
      mouseStatus: false,
      recordings: false, // 标记是否在录音
      intervaltimerid: "", // 间隔时间定时器编号
      form: {
        time: "按住录音",
        audioUrl: "",
      },
      num: 60, // 按住说话时间
      recorder: null,
      interval: "",
      audioFileList: [], // 上传语音列表
      startTime: "", // 语音开始时间
      endTime: "", // 语音结束
      auUrl: "", // 上传录音文件地址
      timeLen: 0,
      obj1: {},
      title: "",
      playStatus: false,
      playUrl: require("../../assets/images/play.png"),
      zantingUrl: require("../../assets/images/zanting_icon@2x.png"),
      timeLength: "00:00:00",
      audio: null,
      tiemr: null,
      tiemrs: "00:00:00",
      daanObj: {},
      datiTime: "00:00:00",
      datiTimes: "00:00:00",
      bofangStatus: false,
      daojishiTime: 40,

      timenum: "", //当前剩余时间
      timeall: "", //音频总长
      LastTime: 0, // 播放时上次的播放进度
      LastWidth: 0, // 播放时上次进度条的长度
    };
  },
  destroyed() {
    clearInterval(intervalTime);
    clearInterval(intervalTimes);
    clearInterval(_this.tiemr);
    _this.audio.load();
  },
  created() {
    _this = this;
    _this.audio = new Audio();
    _this.getParams();
    _this.daojishi();
    // _this.daojishiBack()
  },
  computed: {
    // 进度条
    scrollerwidth: function () {
      // console.log(_this.timenum);
      // 进度条长度计算，当前时间除以总时间乘以百分比
      let a = _this.timenum; //当前剩余时间
      let b = _this.timeall; //音频总长
      var c = ((b - a) / b) * 100 + "%";

      // 保存进度
      _this.LastTime = a;
      // console.log(_this.timenum, "timenum");
      // console.log(_this.timeall, "timeall");
      // _this.LastWidth = c;
      // console.log(c,'进度');
      // console.log(_this.timenum,'时间');
      return c;
    },
  },
  methods: {
    copyDomain() {
      if (_this.topicExerciseId == null) {
        this.$message.error("请申请批改后复制链接");
        return;
      }
      _this.$copyText(_this.$global.baseUrl + "&id=" + _this.topicExerciseId);
      this.$message.success("地址复制成功");
    },
    // 进入页面倒计时40秒开始录音
    daojishiBack() {
      let daojishiBo = setInterval(() => {
        if (_this.daojishiTime <= 0) {
          clearInterval(daojishiBo);
          _this.audio.src = require("../../assets/di.mp3");
          let playPromise;
          playPromise = _this.audio.play();
          _this.playStatus = true;
          if (playPromise) {
            playPromise
              .then(() => {
                // 音频加载成功
                // 音频的播放需要耗时
              })
              .catch((e) => {
                // 音频加载失败
                console.error(e);
              });
          }
          _this.getTime();
        } else {
          _this.daojishiTime--;
        }
      }, 1000);
    },

    // 返回上一页
    goBack() {
      _this.$router.back();
    },

    // 点击词汇
    selectSpan(i) {
      console.log("111");
      let listObj = _this.detail;
      if(listObj.extend.HIW[i].word != '&nbsp;') {
        listObj.extend.HIW.forEach((item, index) => {
            console.log(index);
            console.log(i);
            console.log(item);
            if (index == i && item.bg == 0) {
              item.bg = 1;
            } else if (index == i && item.bg == 1) {
              item.bg = 0;
            }
          });
      }
      _this.detail = listObj;
      _this.$forceUpdate();
    },

    // input change
    // inputChange(e) {
    //     console.log(e);
    //     console.log(e.target.dataset.daan)
    //     console.log(e.target.value)
    //     var daanObj = _this.daanObj;
    //     daanObj[e.target.dataset.daan] = e.target.value
    //     _this.daanObj = daanObj;
    //     console.log(daanObj)
    // },

    playAudioTopic() {
      if (_this.playStatus) {
        _this.audio.pause();
        // _this.timeLength = _this.tiemrs;
        _this.playStatus = false;
        _this.audio.currentTime = _this.detail.duration - _this.LastTime;
        clearInterval(_this.tiemr);
      } else {
        // _this.audio = new Audio();
        // _this.audio.src = _this.detail.file;
        // let playPromise,
        //   second = _this.detail.duration;
        // playPromise = _this.audio.play();
        let playPromise,
          second = _this.LastTime > 0 ? _this.LastTime : _this.detail.duration;
        playPromise = _this.audio.play();
        _this.playStatus = true;
        if (playPromise) {
          playPromise
            .then(() => {
              // 音频加载成功
              // 音频的播放需要耗时
              _this.tiemr = setInterval(() => {
                second--;
                _this.timeLength = _this.formateSeconds(second);
                if (second <= 0) {
                  _this.audio.load();
                  _this.LastTime = 0;
                  _this.timeLength = _this.tiemrs;
                  _this.playStatus = false;
                  clearInterval(_this.tiemr);
                }
              }, 1000);
            })
            .catch((e) => {
              // 音频加载失败
              console.error(e);
            });
        }
      }
    },

    //将秒转化为时分秒
    formateSeconds(endTime) {
      _this.timenum = endTime; //????
      let secondTime = parseInt(endTime); //将传入的秒的值转化为Number
      let min = 0; // 初始化分
      let h = 0; // 初始化小时
      let result = "";
      if (secondTime > 60) {
        //如果秒数大于60，将秒数转换成整数
        min = parseInt(secondTime / 60); //获取分钟，除以60取整数，得到整数分钟
        secondTime = parseInt(secondTime % 60); //获取秒数，秒数取佘，得到整数秒数
        if (min > 60) {
          //如果分钟大于60，将分钟转换成小时
          h = parseInt(min / 60); //获取小时，获取分钟除以60，得到整数小时
          min = parseInt(min % 60); //获取小时后取佘的分，获取分钟除以60取佘的分
        }
      }
      result = `${h.toString().padStart(2, "0")}:${min
        .toString()
        .padStart(2, "0")}:${secondTime.toString().padStart(2, "0")}`;
      return result;
    },
    // 上一题
    shangYiTi() {
      var params = {
        topic_id: _this.id,
        type: _this.detail.topic_type_id,
        exercise_status: localStorage.getItem('lianxiId'),
        topic_collect: localStorage.getItem('collectId'), //收藏 之前添加传的
        pinlvId: localStorage.getItem('pinlvId'),
        tags: localStorage.getItem('tag_id'),
        topic_lecture_id:
          localStorage.getItem('jiangjieId') == 0 ? undefined : localStorage.getItem('jiangjieId') == 1 ? 1 : 0, //名师讲解id 0=没有讲解
      };
      _this.$axios
        .post("exercise.topic/lastTopic", params)
        .then((res) => {
          // console.log(res);
          if (res.code == 1) {
            _this.id = res.data.topic_id;
            _this.$router.replace({
              path: "questionType16?id=" + res.data.topic_id + "&name=识别错词",
            });
            location.reload();
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          _this.$message.error(err.msg);
        });
    },

    // 下一题
    xiaYiTi() {
      var params = {
        topic_id: _this.id,
        type: _this.detail.topic_type_id,
        exercise_status: localStorage.getItem('lianxiId'),
        topic_collect: localStorage.getItem('collectId'), //收藏 之前添加传的
        pinlvId: localStorage.getItem('pinlvId'),
        tags: localStorage.getItem('tag_id'),
        topic_lecture_id:
          localStorage.getItem('jiangjieId') == 0 ? undefined : localStorage.getItem('jiangjieId') == 1 ? 1 : 0, //名师讲解id 0=没有讲解
      };
      _this.$axios
        .post("exercise.topic/nextTopic", params)
        .then((res) => {
          // console.log(res);
          if (res.code == 1) {
            _this.id = res.data.topic_id;
            _this.$router.replace({
              path: "questionType16?id=" + res.data.topic_id + "&name=识别错词",
            });
            location.reload();
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          _this.$message.error(err.msg);
        });
    },

    // 申请批改
    shenQingPiGai() {
      if (_this.huida) {
        var params = {
          topic_exercise_id: _this.topicExerciseId,
        };
        _this.$axios
          .post("exercise.topic_exercise/submit", params)
          .then((res) => {
            console.log(res);
            if (res.code == 1) {
              _this.$message.success("您已提交申请");
            } else {
              _this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
            _this.$message.error(err.msg);
          });
      } else {
        _this.$message.error("您还没答题，请先进行答题");
      }
    },

    // 查看打分
    seeDaFen() {
      if (_this.huida) {
        _this.status = true;
      } else {
        _this.$message.error("您还没答题，请先进行答题");
      }
    },

    // 重做
    chongzuo() {
      _this.time = 0;
      _this.timeLen = 0;
      _this.datiTime = "00:00:00";
      let obj = _this.detail;
      obj.extend.HIW.forEach((item) => {
        item.bg = 0;
      });
      _this.huida = false;
      // _this.daanObj = obj;
      //   let detailObj = _this.detail;
      //   detailObj.extend.extend_SA.forEach((item, index) => {
      //     item.select = 0;
      //   });

      _this.audio.load();
      _this.LastTime = 0;
      _this.LastWidth = 0;
      _this.timenum = _this.detail.duration;
      _this.timeLength = _this.formateSeconds(_this.detail.duration);
      _this.playStatus = false;
      clearInterval(_this.tiemr);
      clearInterval(intervalTime);
      clearInterval(intervalTimes);
      _this.timeLen = 0;
      _this.detail = obj;
      _this.$forceUpdate();
      _this.daojishi();
    },

    // 开始计时
    daojishi() {
      intervalTimes = setInterval(() => {
        _this.timeLen = _this.timeLen + 1;
        _this.datiTimes = _this.formateSeconds2(_this.timeLen);
      }, 1000);
    },

    //将秒转化为时分秒
    formateSeconds2(endTime) {
      let secondTime = parseInt(endTime); //将传入的秒的值转化为Number
      let min = 0; // 初始化分
      let h = 0; // 初始化小时
      let result = "";
      if (secondTime > 60) {
        //如果秒数大于60，将秒数转换成整数
        min = parseInt(secondTime / 60); //获取分钟，除以60取整数，得到整数分钟
        secondTime = parseInt(secondTime % 60); //获取秒数，秒数取佘，得到整数秒数
        if (min > 60) {
          //如果分钟大于60，将分钟转换成小时
          h = parseInt(min / 60); //获取小时，获取分钟除以60，得到整数小时
          min = parseInt(min % 60); //获取小时后取佘的分，获取分钟除以60取佘的分
        }
      }
      result = `${h.toString().padStart(2, "0")}:${min
        .toString()
        .padStart(2, "0")}:${secondTime.toString().padStart(2, "0")}`;
      return result;
    },

    // 提交答案
    submitTopic() {
      let objList = _this.detail.extend.HIW,
        list = [];
      objList.forEach((item) => {
        if (item.bg == 1) {
          list.push(item.word);
        }
      });
      var params = {
        topic_id: _this.id,
        content: list.join(","),
        time: _this.timeLen,
      };
      if (localStorage.getItem("token")) {
        // if (list.length != 0) {
        _this.$axios
          .post("exercise.topic/submit", params)
          .then((res) => {
            console.log(res);
            if (res.code == 1) {
              _this.obj = res.data;
              _this.topicExerciseId = res.data.topicExerciseId;
              _this.huida = true;
              _this.$message.success("答题已完成");
              _this.status = true;
              clearInterval(intervalTime);
              clearInterval(intervalTimes);
            } else {
              _this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
            _this.$message.error(err.msg);
          });
        // } else {
        //   _this.$message.error("您还没答题，请先进行答题");
        // }
      } else {
        _this.$message.error("您还没登录，请先登录");
      }
    },

    // 选择答案
    selectDaan(i) {
      let obj = _this.detail,
        strList = [];
      obj.extend.extend_SA.forEach((item, index) => {
        if (index == i) {
          item.select = 1;
          _this.ansId = item.key;
        } else {
          item.select = 0;
        }
      });
      _this.detail = obj;

      console.log(_this.ansId);
    },
    // 微信分享复制链接
    copyToH5(){

    // console.log('当前的domain', item);return
      let pinyin = 'HIW';
      let tips = pinyin+" "+_this.detail.number+" "+" PTE易王炸AI评分 ";
      this.$copyText(tips+" "+window.location.origin+"/H5Detail?exericise_id="+_this.topicExerciseId+"&topic_id="+ _this.detail.id+"&pinyin="+pinyin+"&title="+encodeURIComponent(_this.detail.name)+"&page=questionType6");
      this.$message.success("地址复制成功")
    },

    // 获取详情
    getDetail() {
      var params = {
        topic_id: _this.id,
      };
      _this.$axios
        .post("exercise.topic/getTopicInfo", params)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            // res.data.extend.extend_SA.forEach((item) => {
            //   item.select = 0;
            // });

            _this.timeall = res.data.duration; //音频总长
            _this.detail = res.data;
            _this.audio.src = res.data.file;

            res.data.extend.HIW.forEach((item) => {
              item.bg = 0;
            });

            // let obj = {};
            // res.data.extend.FIB.forEach((item, index) => {

            // console.log(typeof(item.word))
            //     if(item.index == 1) {
            //         obj[item.word] = '';
            //     }
            // })
            // _this.daanObj = obj;

            _this.timeLength = _this.formateSeconds(res.data.duration);
            _this.tiemrs = _this.formateSeconds(res.data.duration);
            _this.bofangStatus = false;
            _this.mouseStatus = false;
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 获取传递过来的参数
    getParams() {
      _this.id = _this.$route.query.id;
      _this.getDetail();
    },
  },
};
</script>


<style>
.kong {
  font-weight: 600;
  line-height: 22px;
  background-color: #e6e7e9;
  border-radius: 12px;
  padding: 0 12px;
  width: 120px;
  height: 24px;
  display: inline-block;
}
</style>
